<template>
  <ui-card
    v-loading="loading"
    :heading="
      `${$t('actions.create')} ${$tc('models.lesson')} ${
        lesson.courseModule !== null
          ? $t('misc.on') + ' ' + getTranslation(lesson.courseModule.name)
          : ''
      }`
    "
  >
    <lesson-form-v2
      v-if="lesson !== null"
      :lesson="lesson"
      @submit="handleSubmit"
      @cancel="handleCancel"
    />

  </ui-card>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import LessonFormV2 from '../refactor/LessonFormV2';
import { createLesson } from '../api';
import { getModule } from '../../coursemodules/api';

export default {
  data() {
    return {
      lesson: this.getInitLessonModel(),
      loading: false
    };
  },

  components: {
    UiCard,
    LessonFormV2
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        const courseId = this.lesson.courseModule.course.id;
        await createLesson(this.lesson);
        const msg = {
          message: this.$t('feedback.update_success'),
          type: 'success'
        };
        this.$message(msg);
        this.$router.push({ name: 'courses-show', params: { id: courseId } });
        this.loading = false;
      } catch (error) {
        const msg = {
          message: this.$t('feedback.update_failed'),
          type: 'error'
        };
        this.$message(msg);
        this.loading = false;
        // {message: 'nei', type: 'error/success/default => type'}
      }
    },

    getInitLessonModel() {
      const model = {
        name: this.getTranslationFields(),
        sortOrder: 0,
        question: {
          text: this.getTranslationFields(),
          questionType: null,
          alternatives: []
        },
        media: {
          vimeoId: this.getTranslationFields(),
          text: this.getTranslationFields(),
          images: [],
          mediaType: null
        },
        courseModule: null
      };
      return model;
    },

    getTranslationFields() {
      const fields = [];
      fields.push(this.getTranslationField('no'));
      fields.push(this.getTranslationField('en'));
      return fields;
    },

    getTranslationField(lang) {
      const field = {
        lang: lang,
        value: ''
      };
      return field;
    },

    getTranslation(transList) {
      return transList.filter(t => t.lang === this.$i18n.locale)[0].value;
    },

    handleCancel() {
      this.$router.back();
    },

    async getModule(moduleid) {
      this.loading = true;
      const mod = await getModule(moduleid);
      if (mod.maxLessonSortOrder) {
        this.lesson.sortOrder = mod.maxLessonSortOrder + 10;
      }
      this.lesson.courseModule = mod;
      this.loading = false;
    }
  },

  created() {
    this.getModule(this.$router.history.current.params.moduleid);
  }
};
</script>
