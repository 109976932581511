var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('ui-form-field',{attrs:{"label":_vm.$tc('models.alternative', 2)}},[_c('el-badge',{attrs:{"is-dot":"","hidden":_vm.validModel.lesson.question.alternativeHead.any &&
          _vm.validModel.lesson.question.alternativeHead.oneCorrect}},[_c('ui-button',{staticClass:"el-button el-button--small new-alternative-btn",attrs:{"type":"green"},on:{"click":function($event){$event.preventDefault();return _vm.onAdd.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$tc('actions.add_new', 2))+" "+_vm._s(_vm.$tc('models.alternative'))+" ")])],1)],1),_vm._l((_vm.alternatives),function(alt,i){return _c('div',{key:`alt_${i}`},[_c('el-badge',{staticClass:"badge-100",attrs:{"is-dot":"","hidden":_vm.validModel.lesson.question.alternatives.find(
          a =>
            a.id === alt.id &&
            a.prop === 'text' &&
            a.lang === _vm.selectedLanguage
        ) === undefined}},[_c('el-input',{staticClass:"mb-2",attrs:{"placeholder":_vm.$tc('models.alternative', 1)},on:{"change":function($event){return _vm.onChangeText(alt, i)}},model:{value:(alt.text.filter(t => t.lang === _vm.selectedLanguage)[0].value),callback:function ($$v) {_vm.$set(alt.text.filter(t => t.lang === _vm.selectedLanguage)[0], "value", $$v)},expression:"alt.text.filter(t => t.lang === selectedLanguage)[0].value"}},[_c('el-switch',{attrs:{"slot":"prepend","active-color":"#13ce66","active-text":`${alt.isCorrect ? _vm.$t('correct') : _vm.$t('incorrect')}`,"inactive-color":"#ff4949"},on:{"change":function($event){return _vm.onChangeCorrect(alt)}},slot:"prepend",model:{value:(alt.isCorrect),callback:function ($$v) {_vm.$set(alt, "isCorrect", $$v)},expression:"alt.isCorrect"}}),_c('el-button',{attrs:{"slot":"append"},on:{"click":function($event){$event.preventDefault();return _vm.onRemove(alt)}},slot:"append"},[_vm._v(_vm._s(_vm.$t('actions.remove')))])],1)],1)],1)}),(!_vm.validModel.lesson.question.alternativeHead.oneCorrect)?_c('span',{staticClass:"block text-sm mt-2"},[_vm._v(_vm._s(_vm.$t('validation.one_correct_answer')))]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }