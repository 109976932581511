<template>
  <ui-form
    @submit="onClickSubmit"
    :submitDisabled="validModel.countNo !== 0 && validModel.countEn !== 0"
    @cancel="onClickCancel"
  >
    <section>
      <el-row class="language_selection">
        <el-badge
          :hidden="validModel.countNo === 0"
          :value="validModel.countNo"
        >
          <ui-button
            type="grey"
            class=""
            :class="{ 'chosen-language-button': selectedLanguage === 'no' }"
            @click.prevent="toggleLanguage('no')"
            >no</ui-button
          >
        </el-badge>
        <el-badge
          class="ml-2"
          :hidden="validModel.countEn === 0"
          :value="validModel.countEn"
        >
          <ui-button
            type="grey"
            class=""
            :class="{ 'chosen-language-button': selectedLanguage === 'en' }"
            @click.prevent="toggleLanguage('en')"
            >en</ui-button
          >
        </el-badge>
      </el-row>
    </section>

    <section id="name">
      <ui-form-field :label="$t('fields.name')" rules="required">
        <el-badge
          is-dot
          class="badge-100"
          :hidden="validModel.lesson.name[selectedLanguage]"
        >
          <el-input
            type="text"
            v-model="
              lesson.name.filter(t => t.lang === selectedLanguage)[0].value
            "
            @input="handleNameChange"
          ></el-input>
        </el-badge>
      </ui-form-field>
    </section>

    <section id="sortOrder">
      <ui-form-field :label="$t('fields.sort_order')">
        <el-input-number
          v-model="lesson.sortOrder"
          :step="10"
        ></el-input-number>
      </ui-form-field>
    </section>

    <media-selector
      :selectedMediaType="lesson.media.mediaType"
      @onMediaTypeChange="handleMediaTypeChange"
    />
    <!-- media content-->
    <section v-if="lesson.media.mediaType">
      <div
        v-if="
          lesson.media.mediaType.name === 'image' ||
            lesson.media.mediaType.name === 'info'
        "
      >
        <image-collection
          :images="lesson.media.images.filter(i => i.lang === selectedLanguage)"
          :validModel="validModel"
          :selectedLanguage="selectedLanguage"
          :requiredImage="lesson.media.mediaType.name === 'image'"
          @onAddImage="handleAddImage"
          @onDeleteImage="handleDeleteImage"
        />
      </div>
      <div v-if="lesson.media.mediaType.name === 'vimeo'">
        <ui-form-field :label="$t('mediatype.vimeo')" rules="required">
          <el-badge
            is-dot
            class="badge-100"
            :hidden="validModel.lesson.media.vimeo[selectedLanguage]"
          >
            <el-input
              type="number"
              v-model="
                lesson.media.vimeoId.filter(t => t.lang === selectedLanguage)[0]
                  .value
              "
              @input="handleVimeoInputChange(selectedLanguage)"
            ></el-input>
          </el-badge>
        </ui-form-field>
      </div>
      <div>
        <ui-form-field
          :label="$t('mediatype.text')"
          rules="required"
          class="mt-4"
        >
          <el-badge
            is-dot
            class="badge-100"
            :hidden="validModel.lesson.media.text[selectedLanguage]"
          >
            <rich-text-field
              type="text"
              v-model="
                lesson.media.text.filter(t => t.lang === selectedLanguage)[0]
                  .value
              "
              @input="handleMediaTextChange(selectedLanguage)"
            />
          </el-badge>
        </ui-form-field>
      </div>
    </section>
    <!-- question content -->
    <section v-if="showQuestionSection">
      <question
        :question="lesson.question"
        :selectedLanguage="selectedLanguage"
        :validModel="validModel"
        :questionType="lesson.question.questionType"
        @onQuestionTypeChange="handleQuestionTypeChange"
        @onQuestionTextInput="handleQuestionTextChange"
        @onAlternativeAdd="handleAddAlternative"
        @onAlternativeRemove="handleDeleteAlternative"
        @onAlternativeCorrectChange="handleAlternativeCorrectChange"
        @onAlternativeTextChange="handleAlternativeTextChange"
      />
    </section>
  </ui-form>
</template>

<script>
import UiForm from '@/components/ui/UiForm';
import UiFormField from '@/components/ui/UiFormField';
import MediaSelector from './MediaSelectorComponent';
import ImageCollection from './ImageCollectionComponent';
import RichTextField from '@/components/form/RichTextField';
import Question from './QuestionComponent';
import UiButton from '@/components/ui/UiButton';
export default {
  props: {
    lesson: {
      type: Object,
      required: true
    }
  },

  components: {
    UiButton,
    UiForm,
    UiFormField,
    MediaSelector,
    ImageCollection,
    RichTextField,
    Question
  },

  data: function() {
    return {
      selectedLanguage: 'no',
      validModel: {
        lesson: {
          name: {
            no: false,
            en: false
          },
          mediaType: false,
          media: {
            image: {
              no: false,
              en: false
            },
            vimeo: {
              no: false,
              en: false
            },
            text: {
              no: false,
              en: false
            }
          },
          questionType: false,
          question: {
            text: {
              no: false,
              en: false
            },
            alternativeHead: {
              any: false,
              oneCorrect: false
            },
            alternatives: []
          }
        },
        countNo: 0,
        countEn: 0
      },
      negativeId: -1,
      loading: false
    };
  },

  methods: {
    onClickSubmit() {
      if (this.validModel.countNo + this.validModel.countEn === 0) {
        this.$emit('submit');
      } else {
        // console.error('Failed validation', this.validModel);
      }
    },

    onClickCancel() {
      this.$emit('cancel');
    },

    toggleLanguage(lang) {
      this.selectedLanguage = lang;
    },

    handleNameChange() {
      this.reevalutateTotalValidity();
    },

    handleAddImage(image) {
      const newImg = {
        id: this.negativeId--,
        imageId: image.id,
        lang: this.selectedLanguage,
        fileName: image.fileName,
        orientation: null,
        sortOrder: 0,
        mediaId:
          this.lesson.media && this.lesson.media.id
            ? this.lesson.media.id
            : this.negativeId--
      };
      this.lesson.media.images.push(newImg);
      this.reevalutateTotalValidity();
    },

    handleDeleteImage(image) {
      this.lesson.media.images = this.lesson.media.images.filter(
        i => i.id !== image.id
      );
      this.reevalutateTotalValidity();
    },

    getEmptyTranslationField() {
      const field = [
        { id: this.negativeId--, lang: 'no', value: '' },
        { id: this.negativeId--, lang: 'en', value: '' }
      ];
      return field;
    },

    handleAddAlternative() {
      const alternative = this.getEmptyAlternative();
      this.lesson.question.alternatives.push(alternative);
      this.reevalutateTotalValidity();
    },

    handleDeleteAlternative(alternative) {
      this.lesson.question.alternatives = this.lesson.question.alternatives.filter(
        a => a.id !== alternative.id
      );
      this.reevalutateTotalValidity();
    },

    handleAlternativeCorrectChange() {
      this.reevalutateTotalValidity();
    },

    handleAlternativeTextChange() {
      this.reevalutateTotalValidity();
    },

    getEmptyAlternative() {
      const emptyAlternative = {
        id: this.negativeId--,
        questionId: this.lesson.question.id,
        text: [
          { id: this.negativeId--, lang: 'no', value: '' },
          { id: this.negativeId--, lang: 'en', value: '' }
        ],
        isCorrect: false
      };
      return emptyAlternative;
    },

    handleMediaTypeChange(selected) {
      this.lesson.media.mediaType = selected;
      if (selected.name === 'vimeo' && !this.lesson.media.vimeo) {
        this.lesson.media.vimeo = this.getEmptyTranslationField();
      }
      this.reevalutateTotalValidity();
    },

    handleMediaTextChange() {
      this.reevalutateTotalValidity();
    },

    handleVimeoInputChange() {
      this.reevalutateTotalValidity();
    },

    handleQuestionTypeChange(selected) {
      this.lesson.question.questionType = selected;
      if (selected.handle === 'multi' && !this.lesson.question.alternatives) {
        this.lesson.question.alternatives = [];
      }
      this.reevalutateTotalValidity();
    },

    handleQuestionTextChange() {
      this.reevalutateTotalValidity();
    },

    reevalutateTotalValidity() {
      let invalidNoCount = 0;
      let invalidEnCount = 0;
      const no = 'no';
      const en = 'en';
      //name
      if (!this.evaluateProp(no, 'name')) {
        this.validModel.lesson.name.no = false;
        invalidNoCount++;
      } else {
        this.validModel.lesson.name.no = true;
      }
      if (!this.evaluateProp(en, 'name')) {
        this.validModel.lesson.name.en = false;
        invalidEnCount++;
      } else {
        this.validModel.lesson.name.en = true;
      }
      //mediatype
      if (!this.lesson.media.mediaType) {
        this.validModel.lesson.mediaType = false;
        invalidNoCount++;
        invalidEnCount++;
      } else {
        this.validModel.lesson.mediaType = true;

        //vimeo
        if (this.lesson.media.mediaType.name === 'vimeo') {
          if (!this.evaluateProp2(no, 'media', 'vimeoId')) {
            this.validModel.lesson.media.vimeo.no = false;
            invalidNoCount++;
          } else {
            this.validModel.lesson.media.vimeo.no = true;
          }
          if (!this.evaluateProp2(en, 'media', 'vimeoId')) {
            this.validModel.lesson.media.vimeo.en = false;
            invalidEnCount++;
          } else {
            this.validModel.lesson.media.vimeo.en = true;
          }
        }

        if (this.lesson.media.mediaType.name === 'image') {
          const imagesNo = this.lesson.media.images.filter(
            i => i.lang === 'no'
          );
          if (imagesNo.length <= 0) {
            this.validModel.lesson.media.image.no = false;
            invalidNoCount++;
          } else {
            this.validModel.lesson.media.image.no = true;
          }

          const imagesEn = this.lesson.media.images.filter(
            i => i.lang === 'en'
          );
          if (imagesEn.length <= 0) {
            this.validModel.lesson.media.image.en = false;
            invalidEnCount++;
          } else {
            this.validModel.lesson.media.image.en = true;
          }
        }
      }

      //media
      if (!this.evaluateProp2(no, 'media', 'text')) {
        this.validModel.lesson.media.text.no = false;
        invalidNoCount++;
      } else {
        this.validModel.lesson.media.text.no = true;
      }
      if (!this.evaluateProp2(en, 'media', 'text')) {
        this.validModel.lesson.media.text.en = false;
        invalidEnCount++;
      } else {
        this.validModel.lesson.media.text.en = true;
      }

      //questiontype
      if (
        this.lesson.media.mediaType &&
        this.lesson.media.mediaType.name !== 'info'
      ) {
        if (!this.lesson.question.questionType) {
          invalidNoCount++;
          invalidEnCount++;
        } else {
          //Alternatives
          if (this.lesson.question.questionType.handle === 'multi') {
            var altCount = this.lesson.question.alternatives.length;
            //any alternatives
            if (altCount === 0) {
              this.validModel.lesson.question.alternativeHead.any = false;
              invalidNoCount++;
              invalidEnCount++;
            } else {
              this.validModel.lesson.question.alternativeHead.any = true;
              const oneCorrect = this.lesson.question.alternatives.some(
                a => a.isCorrect === true
              );
              //atleast one
              if (!oneCorrect) {
                this.validModel.lesson.question.alternativeHead.oneCorrect = false;
                invalidNoCount++;
                invalidEnCount++;
              } else {
                this.validModel.lesson.question.alternativeHead.oneCorrect = true;
              }

              //all have text
              this.validModel.lesson.question.alternatives = [];
              this.lesson.question.alternatives.map(a => {
                const textNo = a.text.filter(t => t.lang === 'no')[0].value;

                if (textNo === '' || textNo === '<p></p>') {
                  const noNorText = { id: a.id, prop: 'text', lang: 'no' };
                  this.validModel.lesson.question.alternatives.push(noNorText);
                  invalidNoCount++;
                }
                const textEn = a.text.filter(t => t.lang === 'en')[0].value;
                if (textEn === '' || textEn === '<p></p>') {
                  const noEngText = { id: a.id, prop: 'text', lang: 'en' };
                  this.validModel.lesson.question.alternatives.push(noEngText);
                  invalidEnCount++;
                }
              });
            }
          }
        }

        //Question Text
        if (!this.evaluateProp2(no, 'question', 'text')) {
          this.validModel.lesson.question.text.no = false;
          invalidNoCount++;
        } else {
          this.validModel.lesson.question.text.no = true;
        }

        if (!this.evaluateProp2(en, 'question', 'text')) {
          this.validModel.lesson.question.text.en = false;
          invalidEnCount++;
        } else {
          this.validModel.lesson.question.text.en = true;
        }
      }

      this.validModel.countNo = invalidNoCount;
      this.validModel.countEn = invalidEnCount;
    },

    evaluateProp(lang, prop1) {
      const field = this.lesson[prop1].filter(t => t.lang === lang)[0];
      return field.value !== '' && field.value !== '<p></p>' ? true : false;
    },

    evaluateProp2(lang, prop1, prop2) {
      const field = this.lesson[prop1][prop2].filter(t => t.lang === lang)[0];
      return field.value !== '' && field.value !== '<p></p>' ? true : false;
    }
  },

  computed: {
    showQuestionSection: function() {
      let show = false;
      if (this.lesson.media.mediaType) {
        const type = this.lesson.media.mediaType.name;
        switch (type) {
          case 'image':
          case 'vimeo':
          case 'none':
            show = true;
        }
      }
      return show;
    }
  },

  created() {
    this.reevalutateTotalValidity();
  }
};
</script>

<style scoped>
.language_selection {
  margin-bottom: 1em;
}

.chosen-language-button {
  background-color: #c4c4c4 !important;
}
</style>
